import React from 'react';
import { Row, Col, Button} from 'reactstrap';

function ColorBar(props) {
	return(
		<div className="colorBar">
			<Row>
				<Col>		
				</Col>
			</Row>
		</div>
	);
}

export default ColorBar;